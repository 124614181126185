<div class="main w-full h-full overflow-scroll">
  <div
    class="w-full h-full block flex-col items-center justify-center min-w-[920px] min-h-[650px]"
    style="text-align: -webkit-center"
  >
    <div class="w-[99%] h-[10%]">
      <app-plant-details [categoryId]="'1'"></app-plant-details>
    </div>
    <div class="w-[99%] h-[45%] items-center flex flex-row space-x-[1%] mt-5">
      <div
        class="w-[67%] h-[95%] dark:bg-btTh flex items-center rounded-2xl p-4"
      >
        <div
          class="flex flex-col rounded-2xl dark:bg-btTh text-white h-full w-full"
        >
          <div class="flex justify-start pt-3 pl-3">
            <span class="gradient-header">{{
              "rooftop_plant" | translate
            }}</span>
            <div class="inline">
              <img
                class="ml-2 h-5 -mb-2"
                src="assets/images/rooftop.svg"
                alt="Rooftop"
              />
            </div>
          </div>

          <div class="flex flex-row flex-grow">
            <div class="w-[23%] h-full pl-3">
              <div class="flex justify-between w-full h-full">
                <div class="flex flex-col justify-around w-full h-full">
                  <div class="flex flex-row justify-around w-full">
                    <div class="flex-1 text-start">
                      <span class="label-sm text-dark-gray-content">{{
                        "plant" | translate
                      }}</span>
                    </div>
                    <div class="flex-1 text-center">
                      <img
                        src="assets/svg/sun.svg"
                        alt="Plant"
                        class="w-7 h-7"
                      />
                    </div>
                  </div>
                  <div class="flex flex-row justify-around w-full">
                    <div class="flex-1 text-start">
                      <span class="value-large">
                        {{
                          allplantDetails.solarPlantResponse.totalSolarPlants
                        }}</span
                      >
                    </div>
                    <div class="flex-1 text-center"></div>
                  </div>
                  <div class="flex flex-row justify-between w-full">
                    <div class="flex-1 text-start">
                      <span class="label-sm text-dark-gray-content"
                        >Status</span
                      >
                    </div>
                    <div class="flex-1 text-center"></div>
                  </div>
                  <div class="flex flex-row justify-between w-full">
                    <div class="flex-1 text-start">
                      <div class="flex justify-start items-center">
                        <img
                          class="icon-sm"
                          src="assets/svg/online-icon.svg"
                          alt="ONline"
                        />
                        <span class="value-small pl-2">
                          {{
                            allplantDetails.solarPlantResponse.solarPlantsOnline
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="flex-1 text-center">
                      <div class="flex justify-center items-center">
                        <img
                          class="icon-sm"
                          src="assets/svg/offline-icon.svg"
                          alt="offline"
                        />
                        <span class="value-small pl-2">
                          {{
                            allplantDetails.solarPlantResponse
                              .solarPlantsOffline
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center mx-3">
                  <div class="bg-slate-400 lines h-[10rem] opacity-40"></div>
                </div>
              </div>
            </div>
            <div class="w-[36%] h-full">
              <div class="flex justify-between w-full h-full">
                <div class="flex flex-col justify-around w-full h-full">
                  <div class="flex flex-row justify-around w-full">
                    <div class="flex-1 text-start">
                      <span
                        class="label-sm text-dark-gray-content flex mon_sm:w-[101%]"
                      >
                        {{ "lifetime_saving" | translate }}</span
                      >
                    </div>
                    <div class="relative flex-1 text-center">
                      <div class="absolute bottom-[-40px]">
                        <ng-lottie [options]="options[0]"></ng-lottie>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row justify-around w-full">
                    <div class="flex-1 text-start value-medium">
                      {{ allplantDetails.solarPlantResponse.lifetimeSavings }}
                    </div>
                    <div class="flex-1"></div>
                  </div>
                  <div class="flex flex-row justify-between w-full">
                    <div class="flex-1 text-start content-center">
                      <span class="label-lg">{{
                        "return_on_investment" | translate
                      }}</span>
                    </div>
                    <div class="flex-1">
                      <div class="relative">
                        <div
                          class="shape xl:h-[75px] xl:w-[75px] md:h-[55px] md:w-[55px] h-[45px] w-[45px] shadow-md opacity-60 rounded-[50%] dark:bg-btTh"
                        >
                          <div
                            class="wave dark:bg-btTh"
                            [ngStyle]="{
                              top: calculateRoiProgress(
                                allplantDetails.solarPlantResponse.roi
                              )
                            }"
                          ></div>
                        </div>

                        <p class="absolute prog-percent font-normal text-xs">
                          {{
                            allplantDetails.solarPlantResponse.roi > 100
                              ? 100
                              : allplantDetails.solarPlantResponse.roi
                          }}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center mx-2">
                  <div
                    class="bg-slate-400 lines m-auto h-[10rem] opacity-45"
                  ></div>
                </div>
              </div>
            </div>
            <div class="h-full flex flex-grow px-4">
              <!-- grid -->
              <div class="grid grid-cols-12 flex-grow lg:m-2 grid-rows-3">
                <!-- first -->
                <div class="col-span-6 justify-self-start text-start">
                  <span class="label-sm text-dark-gray-content">{{
                    "current_generation" | translate
                  }}</span>
                </div>
                <div class="col-span-1 justify-self-center">:</div>
                <div class="col-span-5 justify-self-end">
                  {{ allplantDetails.solarPlantResponse.currentGeneration }}
                </div>
                <!-- second -->
                <div class="col-span-6 justify-self-start text-start">
                  <span class="label-sm text-dark-gray-content">{{
                    "total_solar_capacity" | translate
                  }}</span>
                </div>
                <div class="col-span-1 justify-self-center">:</div>
                <div class="col-span-5 justify-self-end">
                  {{ allplantDetails.solarPlantResponse.totalSolarCapacity }}
                </div>
                <!-- third -->
                <div class="col-span-6 justify-self-start text-start">
                  <div class="flex flex-col justify-between w-full">
                    <div class="flex align-center flex-wrap">
                      <div class="text-start">
                        <span class="label-sm text-dark-gray-content">{{
                          "today" | translate
                        }}</span>
                      </div>

                      <div class="ml-2 pt-0.5">
                        <img src="assets/images/calender.svg" alt="Lifetime" />
                      </div>
                    </div>

                    <div class="flex-1 text-start mt-2">
                      <span class="value-small">
                        {{ allplantDetails.solarPlantResponse.todayGeneration }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-span-1 justify-self-center">
                  <div class="bg-slate-400 lines h-[2rem] opacity-45"></div>
                </div>
                <div class="col-span-5 justify-self-end">
                  <div class="flex flex-col justify-around w-full">
                    <div class="flex align-center flex-wrap">
                      <div class="text-start">
                        <span class="label-sm text-dark-gray-content">{{
                          "lifetime" | translate
                        }}</span>
                      </div>

                      <div class="ml-2 pt-0.5">
                        <img
                          src="assets/images/lifetime-gen.svg"
                          alt="Lifetime"
                        />
                      </div>
                    </div>

                    <div class="flex-1 text-start mt-2">
                      <span class="value-small">
                        {{
                          convertScientificNotation(
                            allplantDetails.solarPlantResponse
                              .lifetimeGeneration
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- grid end -->

              <div class="flex items-center"></div>
            </div>
          </div>
          <div
            class="flex-wrap h-[0.3px] my-2 bg-slate-500 opacity-40 mx-4"
          ></div>
          <div class="flex w-full">
            <div class="flex justify-between w-full items-center px-2">
              <div class="flex items-center">
                <span class="gradient-header label-xs m-0 leading-tight">
                  {{ "environment_benefits" | translate }}</span
                >
              </div>
              <div class="flex flex-row items-center">
                <div class="flex items-center w-full">
                  <div class="flex items-center">
                    <img
                      src="assets/svg/first.svg"
                      alt="First"
                      class="icon-md"
                    />
                  </div>
                  <div class="w-auto">
                    <span class="ml-2 value-xs font-medium">{{
                      allplantDetails.solarPlantResponse.equivalentTreesPlanted
                    }}</span>
                  </div>

                  <span
                    class="text-dark-gray-content font-normal label-2xs ml-2 leading-snug"
                    >{{ "equivalent_trees_planted" | translate }}</span
                  >
                </div>
              </div>
              <div class="flex flex-row">
                <div class="flex items-center w-full">
                  <div class="flex items-center">
                    <img
                      src="assets/svg/second.svg"
                      alt="First"
                      class="icon-md"
                    />
                  </div>
                  <div class="w-auto">
                    <span class="ml-2 value-xs font-medium">{{
                      convertScientificNotation(
                        allplantDetails.solarPlantResponse.co2EmissionSaved
                      )
                    }}</span>
                  </div>

                  <span
                    class="text-dark-gray-content font-medium label-2xs ml-2 leading-snug"
                    >{{ "co2_emission_saved" | translate }}</span
                  >
                </div>
              </div>
              <div class="flex flex-row">
                <div class="flex items-center w-full">
                  <div class="flex items-center">
                    <img
                      src="assets/svg/last.svg"
                      alt="First"
                      class="icon-md"
                    />
                  </div>
                  <div class="w-auto">
                    <span class="ml-2 value-xs font-medium">{{
                      allplantDetails.solarPlantResponse.coalNotBurned
                    }}</span>
                  </div>

                  <span
                    class="text-dark-gray-content font-normal label-2xs ml-2 leading-snug"
                    >{{ "coal_not_burned" | translate }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-[16%] h-[95%] dark:bg-btTh items-center rounded-2xl p-4">
        <!-- House hold plant -->
        <div class="flex flex-col rounded-2xl dark:bg-btTh text-white h-full">
          <div class="flex justify-start pt-3 pl-1">
            <span class="gradient-header">{{
              "household_plant" | translate
            }}</span>
          </div>
          <div class="w-full mt-5 flex flex-col justify-around flex-grow my-6">
            <div class="flex flex-row justify-between mx-2">
              <div class="flex flex-col xl:space-y-4">
                <p class="text-dark-gray-content label-sm font-normal mt-[5px]">
                  {{ "plant" | translate }}
                </p>
                <p class="value-large">
                  {{
                    allplantDetails.nonSolarPlantResponse.totalNonSolarPlants
                  }}
                </p>
              </div>
              <div class="flex justify-center items-center">
                <img
                  src="assets/svg/household_plant.svg"
                  class="img-sm"
                  alt="plant"
                />
              </div>
            </div>
            <div class="flex flex-col flex-wrap">
              <div class="flex justify-start items-start mx-2">
                <p class="text-dark-gray-content label-sm font-normal">
                  {{ "status" | translate }}
                </p>
              </div>
              <div class="flex justify-start items-start mx-2 mb-3 mt-5">
                <div class="flex justify-items-center items-center">
                  <img
                    class="icon-sm"
                    src="assets/svg/online-icon.svg"
                    alt="online"
                  />

                  <div class="ms-4">
                    <span class="value-small">{{
                      allplantDetails.nonSolarPlantResponse.nonSolarPlantsOnline
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="flex justify-start items-start mx-2">
                <div class="flex justify-items-center items-center">
                  <img
                    class="icon-sm"
                    src="assets/svg/offline-icon.svg"
                    alt="offline"
                  />

                  <div class="ms-4">
                    <span class="value-small">{{
                      allplantDetails.nonSolarPlantResponse
                        .nonSolarPlantsOffline
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-[16%] h-[95%] dark:bg-btTh items-center rounded-2xl p-4">
        <!-- BMS plant -->
        <div class="flex flex-col rounded-2xl dark:bg-btTh text-white h-full">
          <div class="flex justify-start pt-3 pl-1">
            <span class="gradient-header">{{ "bms" | translate }}</span>
          </div>
          <div class="w-full mt-5 flex flex-col justify-around flex-grow my-6">
            <div class="flex flex-row justify-between mx-2 mt-[5px]">
              <div class="flex flex-col xl:space-y-4">
                <p class="text-dark-gray-content label-sm font-normal">
                  {{ "plant" | translate }}
                </p>
                <p class="value-large">
                  {{ allplantDetails.bmsPlantResponse.totalBmsPlants }}
                </p>
              </div>
              <div class="flex justify-center items-center">
                <img
                  src="assets/svg/bms_plant.svg"
                  class="img-sm"
                  alt="plant"
                />
              </div>
            </div>
            <div class="flex flex-col flex-wrap">
              <div class="flex justify-start items-start mx-2">
                <p class="text-dark-gray-content label-sm font-normal">
                  {{ "status" | translate }}
                </p>
              </div>
              <div class="flex justify-start items-start mx-2 mb-3 mt-5">
                <div class="flex justify-items-center items-center">
                  <img
                    class="icon-sm"
                    src="assets/svg/online-icon.svg"
                    alt="online"
                  />

                  <div class="ms-4">
                    <span class="value-small">{{
                      allplantDetails.bmsPlantResponse.bmsPlantsOnline
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="flex justify-start items-start mx-2">
                <div class="flex justify-items-center items-center">
                  <img
                    class="icon-sm"
                    src="assets/svg/offline-icon.svg"
                    alt="offline"
                  />

                  <div class="ms-4">
                    <span class="value-small">{{
                      allplantDetails.bmsPlantResponse.bmsPlantsOffline
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-[99%] h-[45%] items-center flex flex-row space-x-[1%] mt-8">
      <!-- Second row, first column -->

      <div
        class="w-[65%] h-[95%] space-x-[1%] flex items-center justify-between rounded-2xl"
      >
        <div class="w-[90%] flex h-full dark:bg-btTh rounded-2xl p-4 mr-1.5">
          <div
            class="rounded-2xl flex-1 flex flex-col bg-btTh text-white w-full h-full"
          >
            <div class="flex pt-3 pl-3">
              <div>
                <span class="gradient-header"
                  >{{ "plant_health" | translate }}
                </span>
              </div>
              <div class="hidden ml-1.5 mt-[0.28rem]">
                <img
                  src="../../../../assets/images/infoicon.svg"
                  class="h-[15px]"
                  alt="Info"
                />
              </div>
            </div>

            <div
              class="flex flex-row w-full h-full justify-center items-center"
            >
              <div class="flex flex-col justify-center items-center w-[29%]">
                <app-radial-bar
                  [dataLabel]="'Healthy'"
                  [dataSeries]="plantHealthDetails.solarPlantsHealth"
                ></app-radial-bar>
                <div class="mt-4">
                  <p class="value-medium">
                    {{ allplantDetails.solarPlantResponse.totalSolarPlants }}
                  </p>
                </div>
                <div>
                  <p class="label-sm">
                    {{ "solar" | translate }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col items-center justify-center w-[30%]">
                <app-radial-bar
                  [dataLabel]="'Healthy'"
                  [dataSeries]="plantHealthDetails.nonSolarPlantsHealth"
                ></app-radial-bar>
                <div class="mt-4">
                  <p class="value-medium">
                    {{
                      allplantDetails.nonSolarPlantResponse.totalNonSolarPlants
                    }}
                  </p>
                </div>
                <div>
                  <p class="label-sm">
                    {{ "non_solar" | translate }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col items-center justify-center w-[30%]">
                <app-radial-bar
                  [dataLabel]="'Healthy'"
                  [dataSeries]="plantHealthDetails.bmsPlantsHealth"
                ></app-radial-bar>
                <div class="mt-4">
                  <p class="value-medium">
                    {{ allplantDetails.bmsPlantResponse.totalBmsPlants }}
                  </p>
                </div>
                <div>
                  <p class="label-sm">
                    {{ "bms" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-[90%] flex h-full dark:bg-btTh rounded-2xl p-4">
          <!-- Second row, third column -->
          <app-powercut-and-summary
            [categoryId]="'1'"
          ></app-powercut-and-summary>
        </div>
      </div>

      <!-- <div class="w-[30%] h-[95%] dark:bg-btTh rounded-2xl p-4">
        <div
          class="rounded-2xl flex-1 flex flex-col bg-btTh text-white w-full h-full"
        >
          <div class="flex pt-3 pl-3">
            <div>
              <span class="gradient-header"
                >{{ "plant_health" | translate }}
              </span>
            </div>
            <div class="px-[6px] mt-[1.5px]">
              <img
                src="../../../../assets/images/infoicon.svg"
                class="h-[15px]"
                alt="Info"
                matTooltip="{{ getMoreInformation() }}"
                matTooltipClass="tooltipClass"
              />
            </div>
          </div>

          <div class="flex flex-row w-full h-full justify-center items-center">
            <div class="flex flex-col justify-center items-center w-[29%]">
              <app-radial-bar
                [dataLabel]="'Healthy'"
                [dataSeries]="plantHealthDetails.solarPlantsHealth"
              ></app-radial-bar>
              <div class="mt-4">
                <p class="value-medium">
                  {{ allplantDetails.solarPlantResponse.totalSolarPlants }}
                </p>
              </div>
              <div>
                <p class="label-sm">
                  {{ "solar" | translate }}
                </p>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center w-[30%]">
              <app-radial-bar
                [dataLabel]="'Healthy'"
                [dataSeries]="plantHealthDetails.nonSolarPlantsHealth"
              ></app-radial-bar>
              <div class="mt-4">
                <p class="value-medium">
                  {{
                    allplantDetails.nonSolarPlantResponse.totalNonSolarPlants
                  }}
                </p>
              </div>
              <div>
                <p class="label-sm">
                  {{ "non_solar" | translate }}
                </p>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center w-[30%]">
              <app-radial-bar
                [dataLabel]="'Healthy'"
                [dataSeries]="plantHealthDetails.bmsPlantsHealth"
              ></app-radial-bar>
              <div class="mt-4">
                <p class="value-medium">
                  {{ allplantDetails.bmsPlantResponse.totalBmsPlants }}
                </p>
              </div>
              <div>
                <p class="label-sm">
                  {{ "bms" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="w-[31%] h-[95%] dark:bg-btTh rounded-2xl p-4">
        Second row, third column
        <app-powercut-and-summary [categoryId]="'1'"></app-powercut-and-summary>
      </div> -->
      <div class="w-[33%] h-[95%] dark:bg-btTh rounded-2xl p-4">
        <app-today-alerts [categoryId]="'1'"></app-today-alerts>
      </div>
    </div>
  </div>
</div>
