<div class="scroll-auto">
  <div
    [class.open]="isOpen"
    [class.sizefirst]="uploadCounter.length == 2"
    [class.sizesecond]="uploadCounter.length == 3"
    [class.sizethird]="uploadCounter.length == 4"
    class="side-sheet fixed top-[18%] p-6 bottom-0 h-[72%] w-[61%] z-[1000] right-[-100%] bg-[#22282F] rounded-lg my-4 gradient-border"
  >
    <div class="flex flex-col w-[100%] h-[100%]">
      <div class="w-[100%]">
        <div class="mb-[2.5%]">
          <h2 class="text-white text-xl font-semibold">
            {{ plantName }}
          </h2>
        </div>

        <form [formGroup]="ticketForm" class="space-y-6" id="uploadForm">
          <div class="grid grid-cols-4 gap-7 w-[100%] mb-[2%]">
            <div>
              <label
                for="category"
                class="block text-gray-400 text-sm mb-[4%] ml-[0.5%]"
                >Category</label
              >
              <select
                id="category"
                formControlName="category"
                class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-4 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                <option value="" disabled selected>Select</option>
                <option *ngFor="let option of categories" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>

            <div>
              <label for="assignee" class="block text-gray-400 text-sm mb-[4%]"
                >Assignee</label
              >
              <select
                id="assignee"
                formControlName="assignee"
                class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                <option value="" disabled selected>Select</option>
                <option *ngFor="let option of assignees" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>

            <div>
              <label for="priority" class="block text-gray-400 text-sm mb-[4%]"
                >Priority</label
              >
              <select
                id="Priority"
                formControlName="priority"
                class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                <option value="" disabled selected>Select</option>
                <option *ngFor="let option of priorities" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>

            <div>
              <label
                for="ticketStatus"
                class="block text-gray-400 text-sm mb-[4%]"
                >Ticket Status</label
              >
              <select
                id="ticketStatus"
                formControlName="ticketStatus"
                class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                <option value="" disabled selected>Select</option>
                <option *ngFor="let option of ticketStatus" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="mb-[2%] w-[100%]"
            [class.sub1]="uploadCounter.length == 1"
            [class.sub2]="uploadCounter.length == 2"
            [class.sub3]="uploadCounter.length == 3"
          >
            <label for="subject" class="block text-gray-400 text-sm mb-[2%]"
              >Subject</label
            >
            <input
              type="text"
              id="subject"
              formControlName="subject"
              placeholder="Add subject"
              [class.sub1]="uploadCounter.length == 2"
              [class.sub2]="uploadCounter.length == 3"
              [class.sub3]="uploadCounter.length == 4"
              class="w-[48.5%] box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            />
          </div>

          <div class="w-[100%] flex mb-[2%]">
            <div
              class="w-[49%] h-[90%]"
              [class.dis1]="uploadCounter.length == 2"
              [class.dis2]="uploadCounter.length == 3"
              [class.dis3]="uploadCounter.length == 4"
            >
              <label
                for="description"
                class="block resize-none text-gray-400 text-sm mb-2"
                >Description</label
              >
              <textarea
                id="description"
                formControlName="description"
                rows="10"
                placeholder="Add description"
                class="w-[100%] h-[60%] box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              ></textarea>
            </div>

            <div
              class="flex w-[52%] items-center p-2"
              [class.upl1]="uploadCounter.length == 1"
              [class.upl2]="uploadCounter.length == 2"
              [class.upl3]="uploadCounter.length == 3"
            >
              @for (item of images; track $index) {
                <div
                  class="flex-col [class.img1]=[] h-[90%] justify-center align-middle items-center"
                  [class.heiwidth]="images[$index].preview"
                >
                  <div *ngIf="!images[$index].preview" class="mt-[35%] w-[60%]">
                    <input
                      type="file"
                      id="file-{{ $index }}"
                      (change)="onFileSelected($event, $index)"
                    />
                  </div>
                  <div
                    class="h-[70%] w-[60%] justify-center align-middle items-center border-white border-dotted"
                    *ngIf="images[$index].preview"
                  >
                    <img
                      [src]="images[$index].preview"
                      class="max-h-[50%] max-w-[100%] bg-orange-700"
                    />
                  </div>
                  <div
                    *ngIf="images[$index].preview && !images[$index].isUploaded"
                    [class.wid]="images[$index].preview"
                  >
                    <button
                      type="button"
                      (click)="uploadImage($index)"
                      class="p-1.5 box-border w-[60%] border-[0.1px] bg-white border-gray-400 rounded-2xl text-black text-xs mt-[2%]"
                    >
                      <div class="flex justify-center align-middle">
                        <div>
                          <img
                            src="../../../../../assets/images/ticketupload.svg"
                            class="mt-[2%]"
                          />
                        </div>
                        <div class="mt-1">Upload</div>
                      </div>
                    </button>
                  </div>
                </div>
              }

              <div class="bg-[#22282F]">
                <button
                  (click)="addImage()"
                  class="text-black border-none items-center"
                  [class.none]="images.length == 4"
                >
                  <div class="flex justify-center items-center">
                    <div>
                      <img
                        src="../.../../../../../../assets/images/ticketaddmore.svg"
                      />
                    </div>
                    <div><span>Add More</span></div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div class="w-[100%] h-20 mb-[2%]" id="textarea">
            <textarea
              id="commentbox"
              formControlName="comment"
              placeholder="Add Comment"
              rows="2"
              class="box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            ></textarea>
          </div>

          <div
            class="flex space-x-3"
            id="buttons"
            [class.margin1]="images.length == 1"
            [class.margin2]="images.length == 2"
            [class.margin3]="images.length == 3"
          >
            <button
              type="submit"
              (click)="submitFormData()"
              class="box-border border-[0.1px] border-slate-100 rounded-2xl py-[8px] px-5 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            >
              Save
            </button>
            <button
              type="button"
              (click)="toggle()"
              class="box-border border-[0.1px] border-slate-100 rounded-2xl py-[8px] px-5 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isOpen" class="overlay" (click)="toggle()"></div>
