import { filterColumns } from '../../shared/interfaces/filter-columns';
import { plainObject } from '../../shared/interfaces/plain-obj';
import { plantInfo } from '../../shared/interfaces/plant-info';
import {
  non_solarplantResponseList,
  solarplantResponseList,
} from '../../shared/models/plant-management';

export const solarFilterColumns: Array<filterColumns> = [
  {
    name: 'status',
    isSelected: true,
  },
  {
    name: 'dataloggerSN',
    isSelected: true,
  },
  {
    name: 'modelName',
    isSelected: true,
  },
  {
    name: 'inverterRating',
    isSelected: true,
  },
  {
    name: 'solarPVInstalled',
    isSelected: true,
  },
  {
    name: 'solarPower',
    isSelected: true,
  },
  {
    name: 'plantHealth',
    isSelected: true,
  },
  {
    name: 'todayGeneration',
    isSelected: true,
  },
  {
    name: 'lifetimeGeneration',
    isSelected: true,
  },
  {
    name: 'alert',
    isSelected: true,
  },
  {
    name: 'customerName',
    isSelected: true,
  },
  {
    name: 'savings',
    isSelected: true,
  },
  {
    name: 'roi',
    isSelected: true,
  },
  {
    name: 'treesPlanted',
    isSelected: true,
  },
  {
    name: 'co2Saved',
    isSelected: true,
  },
  {
    name: 'coalNotburned',
    isSelected: true,
  },
  {
    name: 'dataloggerSignalStrength',
    isSelected: true,
  },
  {
    name: 'plantCreationDate',
    isSelected: true,
  },
];

export const non_solarFilterColumns: Array<filterColumns> = [
  {
    name: 'status',
    isSelected: true,
  },
  {
    name: 'dataloggerSN',
    isSelected: true,
  },
  {
    name: 'modelName',
    isSelected: true,
  },
  {
    name: 'inverterRating',
    isSelected: true,
  },
  {
    name: 'plantHealth',
    isSelected: true,
  },
  {
    name: 'batteryVoltage',
    isSelected: true,
  },
  {
    name: 'batteryState',
    isSelected: true,
  },
  {
    name: 'powerCutDuration',
    isSelected: true,
  },
  {
    name: 'noofPowerCut',
    isSelected: true,
  },
  {
    name: 'totalBackupCapacity',
    isSelected: true,
  },
  {
    name: 'currentBackupCapacity',
    isSelected: true,
  },
  {
    name: 'alert',
    isSelected: true,
  },
  {
    name: 'customerName',
    isSelected: true,
  },
  {
    name: 'gridVoltage',
    isSelected: true,
  },
  {
    name: 'plantCreationDate',
    isSelected: true,
  },
];

export const plantManagementplantInfo: Array<plantInfo> = [
  {
    description: 'Total Plants',
    count: 0,
  },
  {
    description: 'Online',
    count: 0,
    icon: 'assets/svg/online-icon.svg',
  },
  {
    description: 'Offline',
    count: 0,
    icon: 'assets/svg/offline-icon.svg',
  },
  {
    description: 'Alerts',
    count: 0,
    icon: 'assets/svg/alert-icon.svg',
  },
  {
    description: 'No Alerts',
    count: 0,
    icon: 'assets/svg/noalert-icon.svg',
  },
];

export const plantManagementHeaderKeys: plainObject = {
  plantName: 'Plant Name',
  status: 'Status',
  dataloggerSN: 'Data Logger SN',
  modelName: 'Model Name',
  inverterRating: 'Inverter Rating',
  solarPvConnected: 'Solar PV Connected (Kw)',
  solarPVInstalled: 'Solar PV Installed',
  trend: 'Trend',
  plantHealth: 'Plant Health',
  dailyAvgGeneration: 'Daily Avg Generation (kWh)',
  lifeTimeGeneration: 'Life Time Generation (kWh)',
  alert: 'Alert',
  customerName: 'Customer Name',
  savings: 'Savings',
  roi: 'ROI (%)',
  groupName: 'Group Name',
  lastUpdate: 'Last Update',
  plantCreation: 'Plant Creation',
  solarPower: 'Solar Power',
  todayGeneration: 'Daily Generation',
  lifetimeGeneration: 'Lifetime Generation ',
  treesPlanted: 'Trees Planted',
  co2Saved: 'Co2 Saved',
  coalNotburned: 'Coal Not Burned',
  dataloggerSignalStrength: 'Data Logger Signal Strength',
  plantCreationDate: 'Plant Creation',
  batteryVoltage: 'Battery Voltage',
  batteryState: 'Battery State',
  powerCutDuration: 'Power Cut Duration',
  noofPowerCut: 'Number of Power Cut',
  totalBackupCapacity: 'Total Backup Capacity',
  currentBackupCapacity: 'Current Backup Capacity',
  gridVoltage: 'Grid Voltage',
};

export const nonSolarPlantListHeaders: Array<string> = [
  'status',
  'dataloggerSN',
  'modelName',
  'inverterRating',
  'plantHealth',
  'batteryVoltage',
  'batteryState',
  'powerCutDuration',
  'noofPowerCut',
  'totalBackupCapacity',
  'currentBackupCapacity',
  'alert',
  'customerName',
  'gridVoltage',
  'plantCreationDate',
];
export const solarPlantListHeaders: Array<string> = [
  'status',
  'dataloggerSN',
  'modelName',
  'inverterRating',
  'solarPVInstalled',
  'solarPower',
  'todayGeneration',
  'lifetimeGeneration',
  'plantHealth',
  'alert',
  'customerName',
  'savings',
  'roi',
  'treesPlanted',
  'co2Saved',
  'coalNotburned',
  'dataloggerSignalStrength',
  'plantCreationDate',
];

export const emptysolarplantResponseList: solarplantResponseList = {
  plantId: '',
  plantName: '',
  lastUpdated: '',
  location: '',
  status: '',
  dataloggerSN: '',
  modelName: '',
  inverterRating: '',
  solarPVInstalled: '',
  solarPower: '',
  plantHealth: '',
  todayGeneration: '',
  lifetimeGeneration: '',
  alert: '',
  customerName: '',
  savings: '',
  roi: null,
  treesPlanted: 0,
  co2Saved: '',
  coalNotburned: '',
  dataloggerSignalStrength: '',
  plantCreationDate: '',
};

export const emptynon_solarplantResponseList: non_solarplantResponseList = {
  plantId: '',
  plantName: '',
  location: '',
  status: '',
  dataloggerSN: '',
  modelName: '',
  inverterRating: '',
  lastUpdated: '',
  plantHealth: '',
  alert: '',
  customerName: '',
  savings: 0,
  roi: 0,
  plantCreationDate: '',
  batteryVoltage: '',
  batteryState: '',
  powerCutDuration: '',
  noofPowerCut: 0,
  totalBackupCapacity: '',
  currentBackupCapacity: '',
  gridVoltage: '',
};

export const ticketHistory = [
  'ticketid',
  'subject',
  'logdate',
  'priority',
  'assignee',
  'currentStatus',
  'action',
];
export const ticketHistoryHeaders = {
  ticketid: 'Ticket Id',
  subject: 'Subject',
  logdate: 'Log Date',
  priority: 'Priority',
  assignee: 'Assignee',
  currentStatus: ' Current Status',
  action: 'Action',
};

export const reportDetails = {
  fileName: 'PlantManagementData.xlsx',
  sheetName : 'PlantManagement Data'
}
