import { Component, OnInit, ViewChild, inject, viewChild } from '@angular/core';
import { plainObject } from '../../../../shared/interfaces/plain-obj';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../../../../base/components/base.component';
import { ProductService } from '../../../../shared/service/product.service';
import { ViewticketComponent } from '../viewcomponent/viewcomponent.component';

import { ticketHistory } from '../../../../system-integrator/constants/si.constants';
import { ticketHistoryHeaders } from '../../../../system-integrator/constants/si.constants';
import { Subject } from 'rxjs/internal/Subject';
import { CustomerService } from '../../../services/customer.service';
import {
  AnalyticsTicketHistory,
  TicketHistoryByPlantIdList,
} from '../../../../shared/models/analytics-ticket-history';
import { TicketDialogComponent } from '../ticket-dialog/ticket-dialog.component';
@Component({
  selector: 'app-ticket',
  standalone: true,
  imports: [CommonModule, ViewticketComponent, TicketDialogComponent],
  templateUrl: './ticket.component.html',
  styleUrl: './ticket.component.scss',
})
export class TicketComponent extends BaseComponent implements OnInit {
  id!: string;

  tickets!: Array<TicketHistoryByPlantIdList>;
  ngOnInit(): void {
    this.resolveAllSubscriptions([this.getTableData()]);
  }
  @ViewChild(ViewticketComponent) sideSheet!: ViewticketComponent;
  @ViewChild(TicketDialogComponent) editform!: TicketDialogComponent;
  prodService = inject(ProductService);

  customerService: CustomerService = inject(CustomerService);
  tableKeys: Array<string> = ticketHistory;

  headerKeyMap: plainObject = ticketHistoryHeaders;

  getTableData() {
    let loaderSubject = new Subject<boolean>();

    this.customerService.getTicketHistory().subscribe({
      next: (response: AnalyticsTicketHistory) => {
        loaderSubject.next(true);
        this.tickets = response.data.ticketHistoryByPlantIdList;
        console.log(this.tickets);
      },
      error: (error) => {},
      complete: () => {
        loaderSubject.complete();
      },
    });
    return loaderSubject;
  }
}
