<div
  class="analytics-container relative z-[500] -mt-[1%] max-h-[88%] overflow-y-auto"
>
  <div class="fixed-content">
    <nav>
      <ul class="flex my-1">
        <div class="w-full flex flex-row justify-between px-4">
          <div class="flex space-x-6">
            <!-- @for (tab of analyticalTabs; track $index) {
              <div class="relative">
                <a
                  (click)="onAnalyticalTabChange($index)"
                  [class]="getAnalyticalTabClass($index)"
                  class="px-2 py-1 cursor-pointer transition duration-200"
                >
                  {{ tab }}
                </a>
                <div
                  *ngIf="activeAnalyticalTab === $index"
                  class="absolute bottom-2 left-0 w-full h-0.5 bg-blue-500"
                ></div>
              </div>
            } -->
          </div>

          <div class="relative z-[1000]">
            <div
              class="dark:bg-none px-3 py-[5px] z-[500] dark:text-white rounded-xl border-solid border-[1px] -mt-[1%] border-gray-300 flex items-center space-x-2 cursor-pointer"
              (click)="sideSheet.toggle()"
            >
              <span class="gradient-text">{{
                "Raise a Ticket" | translate
              }}</span>
              <img
                src="../../../../../assets/svg/arrow_forward.svg"
                alt="arrow"
              />
            </div>

            <div class="text-white cursor-default h-[40%]">
              <app-ticket-dialog></app-ticket-dialog>
            </div>
          </div>
        </div>
      </ul>
    </nav>

    <!-- content of analytics -->
  </div>

  <div class="scrollable-content h-[80%] mt-[1%] px-2">
    <div class="py-1 px-4">
      @if (activeAnalyticalTab === 0) {
        <!-- start -->

        <!-- dashboard.component.html -->
        <div class="text-white h-[100%]">
          <div class="grid grid-cols-12 gap-4 h-[90%]">
            <!-- First row -->

            <div class="col-span-2 analytic-bg p-4 rounded-lg shadow boxes">
              <h3 class="text-xs font-semibold">Soiling Loss</h3>
              <div class="text-xl -mt-1">
                {{
                  analyticsData.soilingLoss !== null
                    ? analyticsData.soilingLoss
                    : "N/A"
                }}
              </div>
            </div>

            <div class="col-span-2 analytic-bg p-4 rounded-lg shadow">
              <h3 class="text-xs font-semibold">Shading</h3>
              <div class="text-xl -mt-1">
                {{
                  analyticsData.shading !== null ? analyticsData.shading : "N/A"
                }}
              </div>
            </div>

            <div class="col-span-2 analytic-bg p-4 rounded-lg shadow">
              <h3 class="text-xs font-semibold">Communication loss</h3>
              <div class="text-xl -mt-1">
                {{
                  analyticsData.communicationLoss !== null
                    ? analyticsData.communicationLoss
                    : "N/A"
                }}
              </div>
            </div>

            <div class="col-span-2 analytic-bg p-2 rounded-lg shadow">
              <h3 class="text-xs font-semibold">Mismatch losses</h3>
              <div class="text-xl -mt-1">
                {{
                  analyticsData.missMatchLoss !== null
                    ? analyticsData.missMatchLoss
                    : "N/A"
                }}
              </div>
            </div>

            <div class="col-span-2 analytic-bg p-4 rounded-lg shadow">
              <h3 class="text-xs font-semibold">Since Cleaning</h3>
              <div class="text-xl -mt-1">
                {{
                  analyticsData.sinceCleaning !== null
                    ? analyticsData.sinceCleaning
                    : "N/A"
                }}
              </div>
            </div>

            <div class="col-span-2 analytic-bg p-4 rounded-lg shadow">
              <h3 class="text-xs font-semibold">Alerts</h3>
              @if (analyticsData.alerts == null) {
                <div class="text-xl -mt-1">N/A</div>
              } @else {
                <div class="flex flex-wrap p-2">
                  @for (item of analyticsData.alerts; track $index) {
                    @if (analyticsData.alerts.length < 2) {
                      <span class="text-xl -mt-1 text-blue-500">{{
                        item
                      }}</span>
                    } @else {
                      <span class="text-xl -mt-1 text-blue-500">{{
                        item + ","
                      }}</span>
                    }
                  }
                </div>
              }
            </div>
            <!-- Second row -->

            <div class="col-span-4 analytic-bg p-4 rounded-lg shadow boxes">
              <h3 class="text-xs font-semibold mb-2">Performance</h3>
              <div class="text-xl">
                {{
                  analyticsData.performance !== null
                    ? analyticsData.performance
                    : "N/A"
                }}
              </div>

              <div class="h-[200px] rounded flex items-center justify-center">
                <div
                  echarts
                  [options]="eChartOption!"
                  class="h-[260px] w-[400px]"
                ></div>
              </div>
            </div>
            <div class="col-span-4 h-48 flex flex-col gap-4 boxes">
              <div class="col-span-2 analytic-bg p-4 h-40 rounded-lg shadow">
                <div class="flex flex-row justify-start">
                  <div class="flex flex-row items-center gap-x-2">
                    <h3 class="text-xs font-semibold">Peak System Hours</h3>
                    <h3 class="text-xl">
                      {{
                        analyticsData.peakSystemHours !== null
                          ? analyticsData.peakSystemHours.value
                          : "N/A"
                      }}
                    </h3>
                  </div>
                </div>
                <div class="-mt-6">
                  @if (
                    chartOptionsCurtailment &&
                    chartOptionsCurtailment.chart?.type &&
                    chartOptionsCurtailment.series &&
                    chartOptionsCurtailment.series.length > 0
                  ) {
                    <apx-chart
                      class="w-full h-full"
                      [series]="chartOptionsCurtailment.series!"
                      [chart]="chartOptionsCurtailment.chart!"
                      [xaxis]="chartOptionsCurtailment.xaxis!"
                      [yaxis]="chartOptionsCurtailment.yaxis!"
                      [tooltip]="chartOptionsCurtailment.tooltip!"
                      [dataLabels]="chartOptionsCurtailment.dataLabels!"
                      [stroke]="chartOptionsCurtailment.stroke!"
                      [plotOptions]="chartOptionsCurtailment.plotOptions!"
                      [grid]="chartOptionsCurtailment.grid!"
                      [fill]="chartOptionsCurtailment.fill!"
                    ></apx-chart>
                  } @else {
                    <div class="flex flex-grow justify-center items-center">
                      <span class="text-xs dark:text-white">{{
                        "no_record_found" | translate
                      }}</span>
                    </div>
                  }
                </div>
              </div>

              <div class="col-span-2 analytic-bg p-4 rounded-lg shadow h-16">
                <h3 class="text-sm font-semibold">Insulation Fault</h3>
                <div class="text-xl">
                  {{
                    analyticsData.insulationFault !== null
                      ? analyticsData.insulationFault
                      : "N/A"
                  }}
                </div>
              </div>
            </div>

            <div class="col-span-4 analytic-bg p-4 rounded-lg shadow boxes">
              <h3 class="text-xs font-semibold mb-2">MPPT Losses</h3>
              <div class="h-[200px] rounded text-xl -mt-2">
                @if (
                  analyticsData.mpptLoss !== null &&
                  chartOptionCylindrical &&
                  chartOptionCylindrical.chart?.type
                ) {
                  <apx-chart
                    class="w-full h-full"
                    [series]="chartOptionCylindrical.series!"
                    [chart]="chartOptionCylindrical.chart!"
                    [dataLabels]="chartOptionCylindrical.dataLabels!"
                    [plotOptions]="chartOptionCylindrical.plotOptions!"
                    [yaxis]="chartOptionCylindrical.yaxis!"
                    [xaxis]="chartOptionCylindrical.xaxis!"
                    [fill]="chartOptionCylindrical.fill!"
                    [tooltip]="chartOptionCylindrical.tooltip!"
                  ></apx-chart>
                } @else {
                  <div class="flex flex-grow justify-center items-center">
                    <span class="text-xs dark:text-white">{{
                      "no_record_found" | translate
                    }}</span>
                  </div>
                }
              </div>
            </div>
            <!-- Third row -->

            <div
              class="col-span-4 analytic-bg p-4 pb-1 rounded-lg shadow h-[92%]"
            >
              <h3 class="text-xs font-semibold">Grid uptime</h3>
              <div class="text-xl">
                {{
                  analyticsData.gridUptime !== null
                    ? analyticsData.gridUptime
                    : "N/A"
                }}
              </div>
              <div class="h-[170px] rounded">
                @if (
                  chartOptionsUpTime &&
                  chartOptionsUpTime.chart?.type &&
                  chartOptionsUpTime.series &&
                  chartOptionsUpTime.series.length > 0
                ) {
                  <apx-chart
                    class="w-full h-full"
                    [series]="chartOptionsUpTime.series!"
                    [chart]="chartOptionsUpTime.chart!"
                    [xaxis]="chartOptionsUpTime.xaxis!"
                    [yaxis]="chartOptionsUpTime.yaxis!"
                    [tooltip]="chartOptionsUpTime.tooltip!"
                    [dataLabels]="chartOptionsUpTime.dataLabels!"
                    [stroke]="chartOptionsUpTime.stroke!"
                    [plotOptions]="chartOptionsUpTime.plotOptions!"
                    [grid]="chartOptionsUpTime.grid!"
                    [fill]="chartOptionsUpTime.fill!"
                  ></apx-chart>
                }
              </div>
            </div>
            <!--third row secondc column -->
            <div class="flex-col col-span-4 gap-4 h-[95%]">
              <div
                class="flex-col analytic-bg p-4 rounded-lg shadow h-[37%] gap-4"
              >
                <div><h3>Specific Yield</h3></div>
                <div class="flex justify-between items-center mt-[8%]">
                  <div class="text-2xl">
                    {{
                      analyticsData.speicifYield !== null
                        ? analyticsData.speicifYield
                        : "N/A"
                    }}
                  </div>

                  <div class="-mt-[5%]">
                    <img
                      src="assets/svg/specific-yeild.svg"
                      alt=""
                      height="50"
                      width="50"
                    />
                  </div>
                </div>
              </div>
              <div
                class="flex-col analytic-bg p-4 rounded-lg shadow h-[39%] mt-[3.3%]"
              >
                <div class=""><h3>Voltage Issue</h3></div>
                <div class="flex h-[85%] -mt-[3%] items-center">
                  <div class="flex-col ml-[8%]">
                    <div class="circle">
                      <span class="text-lg">
                        {{
                          analyticsData.lowVoltageError?.count !== null
                            ? analyticsData.lowVoltageError?.count
                            : "N/A"
                        }}
                        <br />
                        <span class="text-white font-light"> Times </span>
                      </span>
                    </div>
                    <div class="mt-[4%] text-xs">
                      <span>Under Voltage</span>
                    </div>
                  </div>
                  <div class="ml-[4%] text-start">
                    <span>
                      Duration <br />
                      <span>{{
                        analyticsData.lowVoltageError?.value !== null
                          ? analyticsData.lowVoltageError?.value
                          : "N/A"
                      }}</span></span
                    >
                  </div>
                  <div class="flex-col" class="ml-[10%]">
                    <div class="circle ml-[4%]">
                      <span class="text-center text-lg">
                        {{
                          analyticsData.highVoltageError?.count !== null
                            ? analyticsData.highVoltageError?.count
                            : "N/A"
                        }}
                        <br />
                        <span class="text-white font-light"> Times</span></span
                      >
                    </div>
                    <div class="mt-[4%] text-xs"><span>Over Voltage</span></div>
                  </div>
                  <div class="ml-[4%] text-start">
                    <span>
                      Duration <br /><span
                        >{{
                          analyticsData.highVoltageError?.value !== null
                            ? analyticsData.highVoltageError?.value
                            : "N/A"
                        }}
                      </span></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!--Under Voltage and Over Voltage ends -->
            <div
              class="col-span-4 analytic-bg p-4 pb-1 rounded-lg shadow h-[92%]"
            >
              <h3 class="text-xs font-semibold mb-2">System Downtime</h3>
              <div class="text-xl mt-2">
                {{
                  analyticsData.systemDowntime !== null
                    ? analyticsData.systemDowntime
                    : "N/A"
                }}
              </div>

              <div class="h-[200px] srounded">
                @if (
                  chartOptions &&
                  chartOptions.chart?.type &&
                  chartOptions.series &&
                  chartOptions.series.length > 0
                ) {
                  <apx-chart
                    class="w-full h-full"
                    [series]="chartOptions.series!"
                    [chart]="chartOptions.chart!"
                    [xaxis]="chartOptions.xaxis!"
                    [yaxis]="chartOptions.yaxis!"
                    [tooltip]="chartOptions.tooltip!"
                    [dataLabels]="chartOptions.dataLabels!"
                    [stroke]="chartOptions.stroke!"
                    [plotOptions]="chartOptions.plotOptions!"
                    [grid]="chartOptions.grid!"
                    [fill]="chartOptions.fill!"
                  ></apx-chart>
                } @else {
                  <div class="flex flex-grow justify-center items-center">
                    <span class="text-xs dark:text-white">{{
                      "no_record_found" | translate
                    }}</span>
                  </div>
                }
              </div>
            </div>

            <!--3rd row ends here 
            -->
          </div>
        </div>
        <!-- end -->
      } @else if (activeAnalyticalTab === 1) {
        <!-- <span>Plant Content</span> -->
      } @else if (activeAnalyticalTab === 2) {
        <!-- <span>Solar Content</span> -->
      } @else if (activeAnalyticalTab === 3) {
        <!-- <span>Inverter Content</span> -->
      }
    </div>
  </div>
</div>
