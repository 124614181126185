<div class="main w-full h-full overflow-scroll">
  <div
    class="w-full h-full block flex-col items-center justify-center min-w-[920px] min-h-[650px]"
    style="text-align: -webkit-center"
  >
    <!-- Header -->
    <div class="w-[99%] h-[10%]">
      <app-plant-details [categoryId]="'2'"></app-plant-details>
    </div>
    <!-- First row -->
    <div
      class="w-[99%] h-[45%] items-center flex flex-row space-x-[1%] justify-between mt-4"
    >
      <div
        class="w-[67%] h-[95%] dark:bg-btTh flex items-center rounded-2xl p-4"
      >
        <div
          class="flex flex-col rounded-2xl dark:bg-btTh text-white h-full w-full"
        >
          <div class="flex justify-start pt-3 pl-3">
            <div class="w-[78%]">
              <span class="gradient-header flex justify-start">{{
                "solar_summary" | translate
              }}</span>
            </div>
            <div>
              <span class="gradient-header">{{
                "plant_health" | translate
              }}</span>
            </div>
          </div>

          <div class="flex flex-row flex-grow">
            <!-- summary -->
            <div class="h-full flex flex-grow">
              <div class="flex justify-between w-full h-full">
                <!-- grid -->
                <div class="grid grid-cols-12 flex-grow m-2 grid-rows-3 px-1">
                  <!-- first -->
                  <div class="col-span-6 justify-self-start text-start">
                    <span class="label-sm text-dark-gray-content">{{
                      "current_generation" | translate
                    }}</span>
                  </div>
                  <div class="col-span-1 justify-self-center">:</div>
                  <div class="col-span-5 justify-self-end">
                    {{ solarPlantResponse.currentGeneration }}
                  </div>
                  <!-- second -->
                  <div class="col-span-6 justify-self-start text-start">
                    <span class="label-sm text-dark-gray-content">{{
                      "total_solar_capacity" | translate
                    }}</span>
                  </div>
                  <div class="col-span-1 justify-self-center">:</div>
                  <div class="col-span-5 justify-self-end">
                    {{ solarPlantResponse.totalSolarCapacity }}
                  </div>
                  <!-- third -->
                  <div class="col-span-6 justify-self-start text-start">
                    <div class="flex flex-col justify-between w-full">
                      <div class="flex align-center flex-wrap">
                        <div class="text-start">
                          <span class="label-sm text-dark-gray-content">{{
                            "today" | translate
                          }}</span>
                        </div>

                        <div class="ml-2 pt-0.5">
                          <img
                            src="assets/images/calender.svg"
                            alt="Lifetime"
                          />
                        </div>
                      </div>

                      <div class="flex-1 text-start mt-2">
                        <span class="value-small">
                          {{ solarPlantResponse.todayGeneration }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-1 justify-self-center">
                    <div class="bg-slate-400 lines h-[2rem] opacity-45"></div>
                  </div>
                  <div class="col-span-5 justify-self-end">
                    <div class="flex flex-col justify-around w-full">
                      <div class="flex align-center flex-wrap">
                        <div class="text-start">
                          <span class="label-sm text-dark-gray-content">{{
                            "lifetime" | translate
                          }}</span>
                        </div>

                        <div class="ml-2 pt-0.5">
                          <img
                            src="assets/images/lifetime-gen.svg"
                            alt="Lifetime"
                          />
                        </div>
                      </div>

                      <div class="flex-1 text-start mt-2">
                        <span class="value-small">
                          {{
                            convertScientificNotation(
                              solarPlantResponse.lifetimeGeneration
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- grid end -->

                <div class="flex items-center">
                  <div
                    class="bg-slate-400 lines m-auto h-[10rem] opacity-45 mx-4"
                  ></div>
                </div>
              </div>
            </div>
            <!-- savings -->
            <div class="w-[30%] h-full">
              <div class="flex justify-between w-full h-full">
                <div class="flex flex-col justify-around w-full h-full px-4">
                  <div class="flex flex-row justify-around w-full">
                    <div class="flex-1 text-start">
                      <span
                        class="label-sm text-dark-gray-content flex mon_sm:w-[101%]"
                      >
                        {{ "lifetime_saving" | translate }}</span
                      >
                    </div>
                    <div class="relative flex-1 text-center">
                      <div class="absolute bottom-[-40px]">
                        <ng-lottie [options]="options[0]"></ng-lottie>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row justify-around w-full">
                    <div class="flex-1 text-start value-medium">
                      {{ solarPlantResponse.lifetimeSavings }}
                    </div>
                    <div class="flex-1"></div>
                  </div>
                  <div class="flex flex-row justify-between w-full sm:gap-x-2">
                    <div class="flex-1 text-start content-center">
                      <span class="label-lg">{{
                        "return_on_investment" | translate
                      }}</span>
                    </div>
                    <div class="flex-1">
                      <div class="relative">
                        <div
                          class="shape roi-icon shadow-md opacity-60 rounded-[50%] dark:bg-btTh"
                        >
                          <div
                            class="wave dark:bg-btTh"
                            [ngStyle]="{
                              top: calculateRoiProgress(solarPlantResponse.roi)
                            }"
                          ></div>
                        </div>

                        <p class="absolute prog-percent font-normal value-2xs">
                          {{
                            solarPlantResponse.roi > 100
                              ? 100
                              : solarPlantResponse.roi
                          }}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center">
                  <div
                    class="bg-slate-400 lines m-auto h-[10rem] opacity-45 mx-4"
                  ></div>
                </div>
              </div>
            </div>
            <!-- healths -->
            <div class="w-[20%] h-full px-4">
              <div class="flex justify-between w-full h-full">
                <div
                  class="flex flex-col justify-around items-center w-full h-full"
                >
                  <div class="flex flex-row justify-around items-center w-full">
                    <div class="flex-1 text-center"></div>
                  </div>

                  <div
                    class="flex flex-col justify-center items-center w-[60%]"
                  >
                    <app-radial-bar
                      [dataLabel]="'Healthy'"
                      [dataSeries]="solarPlantHealth"
                    ></app-radial-bar>
                    <div class="mt-3">
                      <span class="text-xl font-bold leading-[21px]">
                        {{ solarPlantResponse.totalSolarPlants }}
                      </span>
                    </div>
                    <div>
                      <span class="text-sm font-medium leading-[21px]">
                        {{ "Plants" | translate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex items-center"></div>
              </div>
            </div>
          </div>
          <div
            class="flex-wrap h-[0.3px] my-2 bg-slate-500 opacity-40 mx-4"
          ></div>
          <div class="flex w-full">
            <div class="flex justify-between w-full items-center px-2">
              <div class="flex items-center">
                <span class="gradient-header label-xs m-0">
                  {{ "environment_benefits" | translate }}</span
                >
              </div>
              <div class="flex flex-row items-center">
                <div class="flex items-center w-full">
                  <div class="flex items-center">
                    <img
                      src="assets/svg/first.svg"
                      alt="First"
                      class="icon-md"
                    />
                  </div>
                  <div class="w-auto">
                    <span class="ml-2 value-xs font-medium">{{
                      solarPlantResponse.equivalentTreesPlanted
                    }}</span>
                  </div>

                  <span
                    class="text-dark-gray-content font-normal label-2xs ml-2 leading-snug"
                    >{{ "equivalent_trees_planted" | translate }}</span
                  >
                </div>
              </div>
              <div class="flex flex-row">
                <div class="flex items-center w-full">
                  <div class="flex items-center">
                    <img
                      src="assets/svg/second.svg"
                      alt="First"
                      class="icon-md"
                    />
                  </div>
                  <div class="w-auto">
                    <span class="ml-2 value-xs font-medium">{{
                      convertScientificNotation(
                        solarPlantResponse.co2EmissionSaved
                      )
                    }}</span>
                  </div>

                  <span
                    class="text-dark-gray-content font-normal label-2xs ml-2 leading-snug"
                    >{{ "co2_emission_saved" | translate }}</span
                  >
                </div>
              </div>
              <div class="flex flex-row">
                <div class="flex items-center w-full">
                  <div class="flex items-center">
                    <img
                      src="assets/svg/last.svg"
                      alt="First"
                      class="icon-md"
                    />
                  </div>
                  <div class="w-auto">
                    <span class="ml-2 value-xs font-medium">{{
                      solarPlantResponse.coalNotBurned
                    }}</span>
                  </div>

                  <span
                    class="text-dark-gray-content font-normal label-2xs ml-2 leading-snug"
                    >{{ "coal_not_burned" | translate }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-[32%] h-[95%] dark:bg-btTh items-center rounded-2xl p-4">
        <app-powercut-and-summary [categoryId]="'2'"></app-powercut-and-summary>
      </div>
    </div>
    <!-- Second row -->
    <div class="w-[99%] h-[45%] items-center flex flex-row space-x-[1%] mt-8">
      <div class="w-[67%] h-[95%] dark:bg-btTh items-center rounded-2xl p-4">
        <app-trends [categoryId]="'1'"></app-trends>
      </div>
      <div class="w-[32%] h-[95%] dark:bg-btTh items-center rounded-2xl p-4">
        <app-today-alerts [categoryId]="'2'"></app-today-alerts>
      </div>
    </div>
  </div>
</div>
