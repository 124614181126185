<div
  class="max-h-[85%] p-x-[5%] overflow-y-auto overflow-x-hidden justify-center"
>
  <table class="w-full">
    <thead class="bg-btTh sticky p-0">
      <tr>
        @for (item of tableKeys; track $index) {
          <th class="label-xs font-thin whitespace-nowrap">
            {{ headerKeyMap[item] }}
          </th>
        }
      </tr>
    </thead>
    <tbody>
      @for (item of tickets; track $index) {
        <tr>
          <td>{{ item.ticketId || "	-  -" }}</td>
          <td>{{ item.subject || "	-  -" }}</td>
          <td>{{ item.logdate || "  -  -" }}</td>
          <td>{{ item.priority || "	-	 -" }}</td>
          <td>{{ item.assignee || "	-  -" }}</td>
          <td>{{ item.status || "- 	-" }}</td>
          <td>
            <div class="flex justify-center">
              <div>
                <div
                  (click)="sideSheet.toggle()"
                  (click)="sideSheet.viewTicket(item.ticketId)"
                >
                  View
                </div>
              </div>
              <div class="ml-[5%] mr-[5%]">|</div>
              <div>
                <div
                  (click)="editform.toggle()"
                  (click)="
                    editform.openFormEditable(item.ticketId, true, item.id)
                  "
                >
                  Edit
                </div>
              </div>
            </div>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

<div>
  <app-viewticket></app-viewticket>
  <app-ticket-dialog></app-ticket-dialog>
</div>
