import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RadialBarComponent } from '../../radial-bar/radial-bar.component';
import { provideEcharts, NgxEchartsDirective } from 'ngx-echarts';
import { BaseComponent } from '../../../../base/components/base.component';
import { PlantDetailsComponent } from '../../plant-details/plant-details.component';
import { SiService } from '../../../../system-integrator/services/si.service';
import {
  AllPlantDetails,
  AllPlantDetailsdata,
} from '../../../models/all-plant-details';
import { Subject, Subscription, takeUntil, timer } from 'rxjs';
import { TodayAlertsComponent } from '../../today-alerts/today-alerts.component';
import { PowercutAndSummaryComponent } from '../../powercut-and-summary/powercut-and-summary.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  PlantHealthDetails,
  PlantHealthDetailsdata,
} from '../../../models/plant-health-details';
import { CommonModule } from '@angular/common';
import {
  convertValueToBoundary,
  mapBackendToFrontend,
} from '../../../utils/common-utils';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';
import { NumberUtil } from '../../../utils/number-util';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-home-dashboard',
  standalone: true,
  imports: [
    RadialBarComponent,
    NgxEchartsDirective,
    PlantDetailsComponent,
    TodayAlertsComponent,
    PowercutAndSummaryComponent,
    TranslateModule,
    CommonModule,
    LottieComponent,
    MatTooltipModule,
  ],
  templateUrl: './home-dashboard.component.html',
  styleUrl: './home-dashboard.component.scss',
  providers: [provideEcharts()],
})
export class HomeDashboardComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  getMoreInformation() {
    return "Health has been calculated based on yesterday's data, considering multiple factors such as solar generation, inverter status, battery health, and data logger communication status."; // \n represent break line here
  }
  siService: SiService = inject(SiService);
  options: AnimationOptions[] = [
    {
      path: '/assets/lottie/pig-save.json',
    },
  ];
  constructor() {
    super();
  }

  plantHealthDetailsSubscription!: Subscription;
  allPlantDetailsSubscription!: Subscription;

  installer_id: string = '';

  allplantDetails: AllPlantDetailsdata = {
    solarPlantResponse: {
      totalSolarPlants: 0,
      solarPlantsOnline: 0,
      solarPlantsOffline: 0,
      lifetimeSavings: 0,
      roi: 0,
      currentGeneration: '',
      totalSolarCapacity: null,
      todayGeneration: '',
      lifetimeGeneration: '',
      co2EmissionSaved: '',
      equivalentTreesPlanted: 0,
      coalNotBurned: '',
    },
    nonSolarPlantResponse: {
      totalNonSolarPlants: 0,
      nonSolarPlantsOnline: 0,
      nonSolarPlantsOffline: 0,
    },
    bmsPlantResponse: {
      totalBmsPlants: 0,
      bmsPlantsOnline: 0,
      bmsPlantsOffline: 0,
    },
  };

  plantHealthDetails: PlantHealthDetailsdata = {
    bmsPlantsHealth: 0,
    solarPlantsHealth: 0,
    nonSolarPlantsHealth: 0,
  };
  isData = new Subject<boolean>();
  private destroy$ = new Subject<void>();
  private focusSubscription?: Subscription;
  numberUtil = NumberUtil;

  ngOnInit(): void {
    this.installer_id = this.authService.loggedUser.installer_id;

    this.baseUserService.sessionService.startBnWatch(this.router);

    this.fetchAPIData();

    /**
     * Timer listener
     */

    this.baseUserService.timerService
      .getTimerObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((fetchData) => {
        if (fetchData) {
          this.fetchAPIData();
        }
      });
  }

  fetchAPIData() {
    this.loadData([this.baseUserService.powerCutLoading], this.isData);
    this.resolveAllSubscriptions([
      this.getPlantHealth(),
      this.getPlantDetails(),
      this.isData,
    ]);
  }

  ngOnDestroy(): void {
    this.allPlantDetailsSubscription.unsubscribe();
    this.plantHealthDetailsSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.baseUserService.sessionService.resetBnTimer();
  }

  getPlantHealth() {
    let getPlantHealth = new Subject<boolean>();
    this.plantHealthDetailsSubscription = this.siService
      .getPlantHealthDetails(this.installer_id, '1')
      .subscribe({
        next: (value: PlantHealthDetails) => {
          this.plantHealthDetails.solarPlantsHealth = Math.floor(
            value.data.solarPlantsHealth,
          );
          this.plantHealthDetails.nonSolarPlantsHealth = Math.floor(
            value.data.nonSolarPlantsHealth,
          );
          this.plantHealthDetails.bmsPlantsHealth = Math.floor(
            value.data.bmsPlantsHealth,
          );
          this.spinner.hide();
          getPlantHealth.next(true);
        },
        error: (e: Error) => {
          this.logger.error(e.message);
        },
        complete: () => getPlantHealth.complete(),
      });

    return getPlantHealth;
  }

  getPlantDetails() {
    let getPlantDetails = new Subject<boolean>();
    this.allPlantDetailsSubscription = this.siService
      .getAllPlantDetails(this.installer_id, '1')
      .subscribe({
        next: (value: AllPlantDetails) => {
          this.allplantDetails.solarPlantResponse =
            value.data.solarPlantResponse;
          this.allplantDetails.nonSolarPlantResponse =
            value.data.nonSolarPlantResponse;
          this.allplantDetails.bmsPlantResponse = value.data.bmsPlantResponse;
          getPlantDetails.next(true);
          this.spinner.hide();
        },
        error: (e: Error) => {
          getPlantDetails.next(true);
          this.spinner.hide();
        },
        complete: () => getPlantDetails.complete(),
      });
    return getPlantDetails;
  }
  calculateRoiProgress(progress: number): string {
    let pixel = mapBackendToFrontend(progress, -50, 50);
    return pixel + '%';
  }

  convertScientificNotation(input: string): string {
    if (input.length == 0) {
      return 'N/A';
    } else {
      // Split the input into the numerical part and the unit part
      const [numberString, unit] = input.split(' ');

      // Convert the numerical value to a number
      const numberValue = parseFloat(numberString);

      // Round the number to three decimal places
      const roundedValue = numberValue.toFixed(2);

      // Return the result as a string with the unit appended
      return `${roundedValue} ${unit}`;
    }
  }
}
