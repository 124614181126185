<div class="scrollable-y-auto h-[100%] w-[100%] -mt-[0.3%]">
  <div
    class="flex flex-col flex-grow fixed justify-start items-start bg-[#1d232b] rounded-lg m-4 w-[93.5%] h-[91.5%]"
  >
    <div class="flex mt-[1%] ml-[1.8%]">
      <div class="text-white text-[16px]">{{ plantName }}</div>

      <div>
        @if (!deviceOffline) {
          <img
            src="../../../../../assets/svg/online.svg"
            alt="Online"
            class="ps-2"
          />
        } @else {
          <img
            src="../../../../../assets/svg/offline.svg"
            alt="Offline"
            class="ps-2"
          />
        }
      </div>
      <div>
        @if (strength) {
          <img
            [src]="
              '../../../../../assets/svg/' + getStrengthImage(strength) + '.svg'
            "
            class="h-[15px] mx-2"
            alt="strength"
          />
        } @else {
          <fa-icon
            [icon]="faWifi"
            class="ms-3 text-[#E6E4E4] text-sm"
          ></fa-icon>
        }
      </div>
    </div>

    <div class="text-white w-full flex flex-col h-full -mt-[2.2%]">
      <div class="flex flex-col justify-center items-center p-2 pt-2 m-6">
        <app-header-tab
          [tabs]="mainTabs"
          [activeTab]="activeMainTab"
          [isMainTab]="true"
          (tabChange)="onMainTabChange($event)"
        ></app-header-tab>
      </div>

      <div class="h-[95%] z-20 p-3">
        @if (activeMainTab === 0) {
          <app-analytic></app-analytic>
        }
        @if (activeMainTab === 1) {
          <app-ticket></app-ticket>
        }
        @if (activeMainTab === 2) {
          <div
            class="h-full w-full flex justify-center content-center items-center"
          >
            <span>Warranty</span>
          </div>
        }
      </div>
    </div>
  </div>
</div>
