<div class="h-full flex flex-col">
  <div class="flex justify-between pt-3 pl-3 pr-3">
    <span class="gradient-header pr-1">{{ "trends" | translate }} </span>
    <div class="flex space-x-3">
      <div
        class="relative px-3 py-[1px] text-black dark:text-white rounded-2xl border-solid border-[1px] border-gray-300 flex items-center space-x-1 cursor-default"
        (click)="toggleTrendDropdown()"
        onClickOutside
        (onClickOutside)="clickOutside('TREND_TYPE')"
      >
        <span class="text-xs p-0 gradient-header no-underline">{{
          selectedTrend.value
        }}</span>
        <img
          src="../../../../../assets/svg/dropdown_icon.svg"
          alt="arrow"
          class="w-3 h-3"
        />
        <div
          class="absolute min-w-28 p-2 z-30 start-0 end-5 top-4"
          *ngIf="enableTrendDropdown"
          id="dropdown"
        >
          <!-- Dropdown content -->
          <div
            class="flex flex-col mt-2 rounded-lg bg-dark-gray-light lg:px-2 sm:px-1 pb-2 small-square"
          >
            @for (item of trendDropdown; track $index) {
              <span
                class="text-xs gradient-header no-underline pt-2"
                (click)="switchTrends(item)"
              >
                {{ item.value }}
              </span>
            }
          </div>
        </div>
      </div>

      <div
        class="flex justify-center items-center rounded-2xl text-black dark:text-white border-solid border-[1px] border-gray-300 space-x-2 py-1 lg:px-2 sm:px-1"
      >
        <div
          class="relative inline-block rounded-b-sm m-auto mx-4 cursor-pointer"
          (click)="updateTrends(selectedDate, null, null)"
        >
          <span
            [ngClass]="{
              'text-[11px] p-0 text-white ': trendSelection === 'DAY',
              'text-xs p-0 text-white opacity-50': trendSelection !== 'DAY'
            }"
            >{{ "day" | translate }}</span
          >
        </div>
        <div
          class="relative inline-block lg:px-2 sm:px-1 cursor-pointer"
          (click)="toggleDropdown()"
          onClickOutside
          (onClickOutside)="clickOutside('MONTHLY')"
        >
          <span
            [ngClass]="{
              'text-[11px] p-0 text-white ': trendSelection === 'MONTHLY',
              'text-xs p-0 text-white opacity-50': trendSelection !== 'MONTHLY'
            }"
            >{{ "month" | translate }}</span
          >
          <div
            class="absolute min-w-56 p-2 lg:px-2 sm:px-1 z-30 -start-12"
            *ngIf="dropdownVisible"
            id="dropdown"
          >
            <!-- Dropdown content -->
            <div
              class="flex flex-col mt-2 rounded-lg bg-dark-gray-light p-3 small-square"
            >
              <div class="flex flex-row justify-around">
                <img
                  src="../../../../../assets/svg/arrow_back.svg"
                  alt="arrow"
                />
                <span> {{ noxMonthsData.year }} </span>
                <img
                  src="../../../../../assets/svg/arrow_forward.svg"
                  alt="arrow"
                />
              </div>
              <div class="grid grid-cols-4 gap-2 mt-2">
                @for (item of noxMonthsData.months; track $index) {
                  <div
                    class="bg-dark-gray-light-2 p-2 text-center aspect-w-1 aspect-h-1 text-[10px] rounded-md"
                    (click)="updateTrends(null, item.num, null)"
                  >
                    {{ item.name }}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div
          class="relative inline-block lg:px-2 sm:px-1 cursor-pointer"
          (click)="toggleYearDropdown()"
          onClickOutside
          (onClickOutside)="clickOutside('YEARLY')"
        >
          <span
            [ngClass]="{
              'text-[11px] p-0 text-white ': trendSelection === 'YEARLY',
              'text-xs p-0 text-white opacity-50': trendSelection !== 'YEARLY'
            }"
            >{{ "year" | translate }}</span
          >
          <div
            class="absolute min-w-56 min-h-56 p-2 z-30 -start-16"
            *ngIf="yearDropdownVisible"
            id="dropdown"
          >
            <!-- Dropdown content -->
            <div class="flex flex-col mt-2 rounded-lg bg-dark-gray-light p-3">
              <div class="flex flex-row justify-around">
                <img
                  src="../../../../../assets/svg/arrow_back.svg"
                  alt="arrow"
                />
                <span>
                  {{ yearList[0].year }} -
                  {{ yearList[yearList.length - 1].year }}
                </span>
                <img
                  src="../../../../../assets/svg/arrow_forward.svg"
                  alt="arrow"
                />
              </div>
              <div class="grid grid-cols-4 gap-2 mt-2">
                @for (item of yearList; track $index) {
                  <div
                    class="bg-dark-gray-light-2 p-2 text-center text-[10px] rounded-md"
                    (click)="updateTrends(null, null, item.year)"
                  >
                    {{ item.year }}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div class="hidden lg:px-2 sm:px-1">
          <span class="text-xs p-0 text-black dark:text-white opacity-50">{{
            "custom" | translate
          }}</span>
        </div>
      </div>
      <div
        class="lg:px-3 sm:px-2 py-[1px] text-black dark:text-white rounded-2xl border-solid border-[1px] border-gray-300 flex items-center space-x-1"
      >
        <img
          src="../../../../../assets/svg/arrow_back.svg"
          alt="arrow"
          class="w-3 h-3"
          (click)="prevDate()"
        />
        <app-nox-date-picker
          (onDatePicked)="onDateSelected($event)"
          [selectedDate]="selectedDate"
        ></app-nox-date-picker>
        <img
          src="../../../../../assets/svg/arrow_forward.svg"
          alt="arrow"
          class="w-3 h-3"
          (click)="enableNextDate(selectedDate, todayDate) ? nextDate() : null"
          [class]="{
            'opacity-50': !enableNextDate(selectedDate, todayDate)
          }"
        />
      </div>
    </div>
  </div>
  <div class="h-full flex flex-grow relative">
    <div class="absolute w-full h-full">
      <ngx-spinner
        class="w-full h-full"
        bdColor="#1A2028"
        size="small"
        color="#fff"
        type="square-spin"
        name="trendsSpinner"
        [fullScreen]="false"
      >
        <span class="mt-1 text-white">Fetching...</span>
      </ngx-spinner>
    </div>
    @if (chartOptions &&
    chartOptions.chart?.type && chartOptions.series && chartOptions.series.length > 0 && chartOptions.dataLabels && chartOptions.grid && chartOptions.plotOptions) {
      <apx-chart
        class="w-full h-full"
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [xaxis]="chartOptions.xaxis!"
        [yaxis]="chartOptions.yaxis!"
        [tooltip]="chartOptions.tooltip!"
        [dataLabels]="chartOptions.dataLabels!"
        [stroke]="chartOptions.stroke!"
        [plotOptions]="chartOptions.plotOptions!"
        [grid]="chartOptions.grid!"
      ></apx-chart>
    } @else {
      @if (!spinnerShowing) {
        <div class="flex flex-grow justify-center items-center">
          <span class="text-xs dark:text-white">{{
            "no_record_found" | translate
          }}</span>
        </div>
      }
    }
  </div>
</div>
